import React from "react"
import { Router } from "@reach/router"
import { sendEmailOptout } from '../api'

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import Rows from "../components/Rows"
import Jumbotron from "../components/Jumbotron"

import { toast } from "react-toastify"
import { Button, Col, Container, Row, Form, FormGroup, Label, Input, FormText } from 'reactstrap';


const EmailSettings = () => (
  <Layout>
    <Router>
      <EmailOptout path="/email_optout/:username/:token"/>
    </Router>
  </Layout>
)


class EmailOptout extends React.Component {
  state = {
    regularity: null,
  }

  static defaultProps = {
    username: null,
    token: null,
  }

  async componentDidMount(){
    // Parse the url: 
    // https://cinetimes.org/email_optout?path=/api/email_optout?token

  }

  handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { username, token  } = this.props;
    const { regularity } = this.state;
    try {
      await sendEmailOptout( username, token, regularity )
      toast.success('Paramètres mis à jour')
    } catch(err) {
      console.log(err)
      toast.error(err.message)
    }
  }

  render() {
    return(
        <Container>
          <Row>
            <Col>
              <Form className="mx-0 mx-sm-5 mt-5 mb-4" onSubmit={this.handleSubmit}>

                <FormGroup tag="fieldset">
                  <h4 className="font-weight-bold">Réglages de la newsletter</h4>
                  <span>La newsletter de cinetimes est généralement envoyée une fois par semaine, sauf cas particuliers.</span>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="regularity" onChange={this.handleInputChange} value="2" checked={this.state.regularity === '2'}/>{' '}
                      <span className="font-weight-bold">Régulier: </span> Jusqu'à une fois par jour
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="regularity" onChange={this.handleInputChange} value="1" checked={this.state.regularity === '1'}/>{' '}
                      <span className="font-weight-bold">Hebdomadaire: </span>  Seulement une fois par semaine
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="regularity" onChange={this.handleInputChange} value="0" checked={this.state.regularity === '0'}/>{' '}
                      <span className="font-weight-bold">Jamais: </span> Ne pas reçevoir la newsletter de Cinetimes
                    </Label>
                  </FormGroup>
                </FormGroup>
    
                <Button size="sm" disabled={!this.state.regularity} >Sauvegarder</Button>
              </Form>
            </Col>
          </Row>
        </Container>
    )
  }

}

export default EmailSettings
